<!-- 订单列表头部 -->
<template>
  <div class="myordertable">
    <table>
      <tr>
        <th>商品</th>
        <th>收货人</th>
        <th>订单金额（元）</th>
        <th>订单状态</th>
        <th>操作</th>
      </tr>
      <template v-for='item,index in arrdata'>
        <tr>
          <td  colspan="5">
            订单号：<span class="sp">{{item.OrderName}}</span><span class='sp1' unselectable="on" style="-moz-user-select:none;" onselectstart="return false;"> {{item.ShippingDate | timereset}}</span>
            <!-- <button @click='delItem' title="删除订单" class="del"><svg><use xlink:href="#iconshanchu1"></use></svg></button> -->
          </td>
        </tr>
        <tr>
          <td>
            <div class="tdbox" v-for='items,indexs in item.orderDeailJobList' :key='indexs'>
              <div class="img" @click='linkdetail(items)'>
                <img :src="items.ImageUrl | imgsrcs(that)">
              </div>
              <div class="info">
                <h6 @click='linkdetail(items)'>{{items.JobName | jobnames(0)}}</h6>
                <p>数量：{{items.DzproductnumberName}}</p>
              </div>
              <div class="info1">
                <p>规格：{{items.DzspecificationName}}</p>
                <p>材质：{{items.DzmaterialName}}</p>
                <p>X{{items.JobNum}}</p>
              </div>
            </div>
          </td>
          <td>
            <p class="n">{{item.ContactName}}</p>
          </td>
          <td>
            <p class="p">&yen;{{item.TotalAmount}}</p>
          </td>
          <td>
            <p class="tp" v-if='item.PayStatusID==0&&item.StatusID!=7'>待付款</p>
            <p class="tp2" v-else-if='item.StatusID<2&&item.ISDelivery==0'>待发货</p>
            <p class="tp2" v-else-if='item.StatusID<2&&item.ISDelivery!=0'>待收货</p>
            <p class="tp3" v-else-if='item.StatusID==2&&item.evaluateNum==0'>待评价</p>
            <p class="tp3" v-else-if='item.StatusID==2&&item.evaluateNum>0'>已完成</p>
            <p class="tp1" v-else-if='item.StatusID==7'>已取消</p>
            <!-- <p class="tp1" v-else-if='item.StatusID==8||item.StatusID==9'>退/换货</p> -->
            <p class="tp1" v-else-if='item.StatusID==8'>全额退款</p>
            <p class="tp1" v-else-if='item.StatusID==9'>部分退款</p>
 <!--            <p class="tp1" v-else-if='item.StatusID==0'>待审核</p>
            <p class="tp2" v-else-if='item.StatusID==1'>已审核</p>
            <p class="tp3" v-else-if='item.StatusID==2'>已完成</p>
            <p class="tp3" v-else-if='item.StatusID==3'>已拒绝</p>
            <p class="tp3" v-else-if='item.StatusID==4'>已取消</p>
            <p class="tp3" v-else-if='item.StatusID==5'>待配送</p>
            <p class="tp3" v-else-if='item.StatusID==6'>已确认收货</p>
            <p class="tp3" v-else-if='item.StatusID==7'>已作废</p>
            <p class="tp3" v-else-if='item.StatusID==8'>全额退款</p>
            <p class="tp3" v-else-if='item.StatusID==9'>部分退款</p>
            <p class="tp3" v-else-if='item.StatusID==-1'>已删除</p> -->
            <el-popover
              v-if='item.StatusID<2&&item.PayStatusID!=0'
              placement="left"
              trigger="hover">
              <div class="cntbox-logisticsinfo">
                <!-- <h6>普通快递</h6>
                <el-timeline>
                  <el-timeline-item
                    v-for="(activity, index) in activities"
                    :key="index"
                    :timestamp="activity.timestamp">
                    {{activity.content}}
                  </el-timeline-item>
                </el-timeline> -->
                 <el-timeline>
                  <el-timeline-item v-if='logisticsData&&logisticsData.TracesList.length>0'>
                    <div class="info">
                      <h6>{{logisticsData.state!=3?'运输中':'已签收'}}<p><span>{{logisticsData.TracesList[logisticsData.TracesList.length-1].AcceptTime | getTimet2}}</span><span class='sp'>{{ logisticsData.TracesList[logisticsData.TracesList.length-1].AcceptTime | getTimet3}}</span></p>
                        <svg v-show='logisticsData.state!=3'><use xlink:href="#iconyunshuzhong1"></use></svg>
                        <svg v-show='logisticsData.state==3'><use xlink:href="#iconyiqianshou"></use></svg>
                      </h6>
                      <!-- <p class="p" v-for='items,indexs in logisticsData.TracesList' :key='indexs'>您的订单已进入第三方卖家仓库，准备出库</p> -->
                      <!-- <p class="p">您的订单已进入第三方卖家仓库，准备出库<span class='sp'>17:41:44</span></p> -->
                      <p class="p" v-for='items,indexs in logisticsData.TracesList' :key='indexs'>{{items.AcceptStation}}<span v-show='indexs!=0' class='sp'>{{items.AcceptTime | getTimet3}}</span></p>
                    </div>
                  </el-timeline-item>
                  <!-- <el-timeline-item>
                    <div class="info">
                      <h6>仓库处理中<p><span>{{item.DeliveryDate | getTimet1}}</span><span class='sp'>{{item.DeliveryDate | getTimet}}</span></p><svg><use xlink:href="#iconcangkuchulizhong"></use></svg></h6>
                      <p class="p">您的订单已进入第三方卖家仓库，准备出库</p>
                    </div>
                  </el-timeline-item> -->
                  <el-timeline-item v-if='logisticsData1&&logisticsData1.length>0'>
                    <div class="info">
                      <h6 >仓库处理中<p><span>{{logisticsData1[logisticsData1.length-1].OperateDate | getTimet1}}</span><span class="sp">{{logisticsData1[logisticsData1.length-1].OperateDate | getTimet4}}</span></p><svg><use xlink:href="#iconcangkuchulizhong"></use></svg></h6>
                      <p class="p" v-for='items,indexs in logisticsData1' :key='indexs'>{{items.msg}}<span v-show='indexs!=0'  class='sp'>{{items.OperateDate | getTimet4}}</span></p>
                    </div>
                  </el-timeline-item>
                  <el-timeline-item >
                    <div class="info">
                      <h6>已下单<p><span>{{item.OrderDate | getTimet1}}</span><span class="sp">{{item.OrderDate | getTimet}}</span></p><svg><use xlink:href="#iconyixiadan"></use></svg></h6>
                      <p class="p">您提交了订单，请等待系统确认</p>
                    </div>
                  </el-timeline-item>
                </el-timeline>
              </div>
              <p slot="reference" @mouseenter='mouseenter(item)' class="plgs"><svg><use xlink:href="#iconwuliu"></use></svg>物流跟踪</p>
            </el-popover>
            <button title="申请售后" @click='applyafter(item)' v-if='item.PayStatusID===2&&item.StatusID!=2&&item.PaymentTypeID!=7&&item.PaymentTypeID!=6' class='sqsh'><svg><use xlink:href="#iconshouhouguanli"></use></svg>申请售后</button>
            <button @click='linkDetail(item)' title="订单详情" class="pt">订单详情</button>
           <!--  <template v-if=''>
              <button @click='againBuy(item)' class="qxtm">再次购买</button>
            </template> -->
          </td>
          <td>
            <div class="handle">
              <div class="lt">
                <div class="ltbox">
                  <template v-if='item.PayStatusID==0&&item.StatusID!=7'>
                    <p class="ltm" ><svg><use xlink:href="#iconshizhong"></use></svg>{{item.ShippingDate | waitTime}}</p>
                    <button v-show='timeEnd(item.ShippingDate)' @click='paynoworder(item)' class="nfk">立即付款</button>
                    <button @click='cancelOrder(item)' class="qxtm">取消订单</button>
                  </template>
                  <template v-if='item.StatusID==7||item.StatusID==2'>
                    <button @click='againBuy(item)' class="nfk">再次购买</button>
                  </template>
                  <template v-if='item.PayStatusID!=0&&item.StatusID!=7&&item.StatusID!=2'>
                    --
                  </template>
                </div>
              </div>
              <div class="rt">
                <div  class="tddiv" v-for='items,indexs in item.orderDeailJobList' :key='indexs'>
                  <template v-if='item.StatusID==2'>
                    <button v-if='items.isproductEvalute==0' class="nfk" @click='rmkbtm(items,item.OrderName)'>评价</button>
                  </template>
                  <template v-if='item.StatusID!=2'>
                    --
                  </template>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </table>
    <div class="page">
      <button class="back"  @click='back'>上一页 </button>
      <el-pagination
        @current-change='pages'
        :page-size='10'
        background
        layout="pager"
        :current-page.sync='pageNum'
        :total="TotalCount">
      </el-pagination>
      <button  class="next"  @click='next'>下一页</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'myordertable',
  data(){
    return {
      activities: [{
        content: '货物已由厂家自送，请您准备收货',
        timestamp: '2018-04-15 08:35:35',
        size:'normal',
      }, {
        content: '第三方卖家已经开始拣货',
        timestamp: '2018-04-13 08:35:35',
        size:'normal',
      }, {
        content: '您的订单已进入第三方卖家仓库，准备出库',
        timestamp: '2018-04-11 08:35:35',
        size:'normal'
      }, {
        content: '您提交了订单，请等待第三方卖家系统确认',
        timestamp: '2018-04-11 08:35:35',
        size:'normal'
      }],
      pageNum:1,//查询参数
      // lengths:500,//列表数据length
      that:this,
      nowDate:Date.now(),
      t:0,
      arrdata:[],
      arrdata1:[],
      logisticsData:null,//物流信息
      logisticsData1:null,//物流信息
    }
  },
  props: {
    arr: Array,
    TotalCount:Number,
    resultNum:Number,//重置计数
  },
  methods:{
    linkdetail(item){
      // 跳转详情
      var _idcx = item.DynamicId?item.DynamicId:''
      // 跳转详情
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id::res.data.Data.producttypeid,idcx:'',detailType:1}})
        var id  = item.JobSubsTypesId + '.html'
        this._open({path:'/predetail',query:{idcx:_idcx,id:id,}})
      }})
    },
    applyafter(item){
      // 申请售后
      var _ids = '';
      for(let i in item.orderDeailJobList){
        if(item.orderDeailJobList===1){
          _ids=item.orderDeailJobList[i].ProductType
        }else{
          if(i==(item.orderDeailJobList.length-1)){
            _ids+=item.orderDeailJobList[i].ProductType
          }else{
            _ids+=item.orderDeailJobList[i].ProductType+','
          }
        }
      }
      this._axios({then:()=>{
        // 申请售后
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/CheckRefundApply?OrderName='+item.OrderName+'&type=1'+'&ProductId='+_ids,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              if(!res.data.Data.RefundApplyId){
                this._router({then:()=>{
                  this.$router.push({path:"/applyafter?ProductId="+_ids+"&OrderName="+item.OrderName+'.html'})
                },exp:true,catch:()=>{
                  var pms = this.$route.fullPath + '.html'
                  this.$router.push({path:'/accountlogin',query:{redirect:pms}})
                  // this._open({path: "/accountlogin",query: {redirect:'/'}})
                }})
              }else{
                // 已经在申请售后跳转申请售后审核或结果
                this._axios({then:()=>{
                  this.axios.post(process.env.VUE_APP_URL+'UserCenter/RefundApplyPage?id='+res.data.Data.RefundApplyId,this.keys())
                  .then(res => {
                      if(res.data.Success){
                        if(res.data.Data.RefundType===1){
                          this._router({then:()=>{
                            this.$router.push({path:"/applyaftercause?ProductId="+_ids+'&OrderName='+item.OrderName+'.html'})
                          },exp:true,catch:()=>{
                            var pms = this.$route.fullPath + '.html'
                            this.$router.push({path:'/accountlogin',query:{redirect:pms}})
                            // this._open({path: "/accountlogin",query: {redirect:'/'}})
                          }})
                        }else if(res.data.Data.RefundType===2){
                          this._router({then:()=>{
                            this.$router.push({path:"/returngood?ProductId="+_ids+'&OrderName='+item.OrderName})
                          },exp:true,catch:()=>{
                            this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
                            // this._open({path: "/accountlogin",query: {redirect:'/'}})
                          }})
                        }
                      }else{
                        this.$MessageBox.alert(res.data.Error,{type:'error'});
                      }
                    }, res => {
                      // this.$MessageBox.alert('系统异常',{type:'error'});
                    })
                },exp:true,catch:()=>{
                      this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
                      // this._open({path: "/accountlogin",query: {redirect:'/'}})
                }})
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    rmkbtm(item,v){
      this._router({then:()=>{
        this.$router.push({path: "/remarkinfo?ProductType="+item.ProductType+'&orderName='+v+'.html'})
        // this._open({path: "/remarkinfo?ProductType="+item.ProductType})
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:"/remarkinfo?ProductType="+item.ProductType+'&orderName='+v+'.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    mouseenter(item){
      // 打开物流
      // console.log(1)
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainTraceInfoByOrderName?OrderName='+item.OrderName,this.keys())
        .then(res => {
            if(res.data.Success){
              this.logisticsData = res.data.Data
            }
          }, res => {
            console.log('物流请求失败')
          })
      },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:'/usercenterorder.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainOrderStatusLog?OrderName='+item.OrderName,this.keys())
        .then(res => {
            if(res.data.Success){
              this.logisticsData1 = res.data.Data
            }
          }, res => {
            console.log('物流请求失败')
          })
      },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:'/usercenterorder.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
    },
    againBuy(item){
      // console.log(item)
      // return;
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/AgainBuy?orderName='+item.OrderName,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              // 返回购物车
              // console.log(this.buylist)
              // return;
              for(let i in res.data.Data){
                res.data.Data[i].id = res.data.Data[i].jobID
                res.data.Data[i]._choice = true
              }
              window.sessionStorage.setItem('carlist',JSON.stringify(res.data.Data))
              this._router({exp:false,catch:()=>{
                // this.$router.push({path:'accountlogin',query:{redirect:'buycar'}})
                // this._open({path: "/accountlogin",query: {redirect:'buycar'}})
              },then:()=>{
                this.$router.push('/buycar.html')
              }})
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'warning'});
            }
          }, res => {
            console.log('再次购买异常')
          })
      },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:'/usercenterorder.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
    },
    // waitTime(v,index){
    //   // 24小时等待倒计时
    //   var _date = new Date(v).getTime()+86400000;
    //   var _nowDate = Date.now();
    //   var _waitDate = _date - _nowDate
    //   if(_waitDate>0){
    //     this.arr[index].ShippingDate = Math.floor(_waitDate/1000/60/60%24)
    //     console.log(_waitDate/1000/60/60%24)
    //     setTimeout(()=>{
    //       this.waitTime(v)
    //     },1000)
    //   }else{
    //     return '已作废'
    //     // this.$emit('exportTypes',0)
    //   }
    // },
    paynoworder(i){
      // 立即付款
      // this.$router.push({path:'payment',query:{ordername:i.OrderName}})
      this._router({then:()=>{
        // this.$router.push('buycar')
        this.$router.push({path:'/payment',query:{ordername:i.OrderName+'.html'}})
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:'/payment?ordername='+i.OrderName+'.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    timeEnd(v){
      // 24小时等待倒计时
      var _date = new Date(v).getTime()+86400000;
      var _nowDate = Date.now();
      var _waitDate = _date - _nowDate
      if(_waitDate<=0){
        return false
      }else{
        return true
      }
    },
    pages(v){
      // 选择页码
      // console.log(v)
      this.$emit('exportPages',v)
    },
    back(){
      // 分页回退
      this.pageNum-=1
      if(this.pageNum<1){
        this.pageNum=1
      }
    },
    next(){
      // 分页前进
      this.pageNum+=1
      if(this.pageNum>=Math.ceil(this.TotalCount/10)){
        this.pageNum=Math.ceil(this.TotalCount/10)
      }
    },
    confirmTake(){
      // 确认收货
      this.$MessageBox.confirm('请确认是否已收到货？','确认收货',{
        confirmButtonText: '确认',
        cancelButtonText: '关闭',
        customClass:'joinCar',
        center:true,
      }).then(() => {
        this.$MessageBox.confirm('<p><svg><use xlink:href="#iconchenggong"></use></svg>确认收货成功</p>','确认收货',{
          confirmButtonText: '立即评价',
          cancelButtonText: '关闭',
          customClass:'joinCar',
          center:true,
          dangerouslyUseHTMLString: true
        }).then(() => {
          // console.log('立即评价')
        }).catch(() => {
          // console.log('关闭')
        });
        // console.log('确认收货')
      }).catch(() => {
        // console.log('关闭')
      });
      // end
    },
    delItem(){
      // 删除订单
      this.$MessageBox.confirm('确定要删除该订单吗?','删除订单',{
        confirmButtonText: '确认',
        cancelButtonText: '关闭',
        customClass:'joinCar',
        center:true,
      }).then(() => {
        // console.log('确认收货')
      }).catch(() => {
        // console.log('取消')
      });
      // end
    },
    cancelOrder(item){
      // 取消订单
      this.$MessageBox.confirm('确定要取消该订单吗?','取消订单',{
        confirmButtonText: '确认',
        cancelButtonText: '关闭',
        customClass:'joinCar',
        center:true,
      }).then(() => {
        var CustomerID='';
        if(this.$store.state.user){
          if(this.$store.state.user.CustomerID){
            CustomerID = this.$store.state.user.CustomerID
          }
        }
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/cancelOrders?orderName='+item.OrderName,this.keys({token:this._token({method:'get'})}))
          .then(res => {
              if(res.data.Success){
                this.$emit('exportresult')
              }
            }, res => {
              console.log('系统异常')
            })
        },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:'/usercenterorder.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }).catch(() => {
        // console.log('取消')
      });
      // end
    },
    linkDetail(item){
      // 跳转订单详情
      this._router({then:()=>{
        this.$router.push({path:'/orderdetail',query:{OrderName:item.OrderName+'.html'}})
      },exp:true,catch:()=>{
        // OrderName=2005191026597231&StatusID=0&PayStatusID=0&ISDelivery=0
          this.$router.push({path:'/accountlogin',query:{redirect:'/orderdetail?OrderName='+item.OrderName+'.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      var _ordersearchdata = JSON.parse(window.sessionStorage.getItem('ordersearchdata'))
      if(_ordersearchdata){
        this.pageNum = _ordersearchdata.pageIndexs
      }
      this.arrdata = this.arr
      this.arrdata1 = this.arr
      this.t = setInterval(()=>{
        this.nowDate = Date.now()
      },1000)
      // // 倒计时
      // for(let i in this.arr){
      //   this.waitTime(this.arr[i].ShippingDate,i)
      // }
    })
  },
  filters:{
    jobnames(v,i){
      // 处理名称
      var _str = v.indexOf('[');
      var _end = v.indexOf(']');
      var _s = v.substring(_str+1,_end)
      var _name =v.substring(0,_str)
      var _number = _s.split('-')[2]
      var _tip = _s.split('-')[1]
      var _tip1 = _s.split('-')[0]
      if(i==0){
        return _name
      }
      if(i==1){
        return _number
      }
      if(i==2){
        return _tip
      }
      if(i==3){
        return _tip1
      }
    },
    imgsrcs(v,that){
      return that._src + v
    },
    timereset(v){
      if(v){
        return v.split('T')[0]+' '+v.split('T')[1]
      }else{
        return ''
      }
    },
    waitTime(v){
      // 24小时等待倒计时
      var _date = new Date(v).getTime()+86400000;
      var _nowDate = Date.now();
      var _waitDate = _date - _nowDate
      if(_waitDate>0){
        // console.log(_waitDate/1000/60/60%24)
        // 剩余0时28分
        return '剩余'+Math.floor(_waitDate/1000/60/60%24)+'时'+Math.floor(_waitDate/1000/60%60)+'分'+Math.floor(_waitDate/1000%60)+'秒';
      }else{
        return '超时';
      }
    },
    getTimet(v){
      // 获取时分秒
      if(v){
        return v.split('T')[1]
      }else{
        return '';
      }
    },
    getTimet1(v){
      // 获取日期和星期
      if(v){
        var _dae = v.split('T')[0];
        var _days = ['周日','周一','周二','周三','周四','周五','周六'][new Date(v).getDay()];
        return v.split('T')[0]+'/'+_days
      }else{
        return '';
      }
    },
    getTimet2(v){
      // 物流-获取日期和星期
      if(v){
        var _dae = v.split(' ')[0];
        var _days = ['周日','周一','周二','周三','周四','周五','周六'][new Date(v).getDay()];
        return v.split(' ')[0]+'/'+_days
      }else{
        return '';
      }
    },
    getTimet3(v){
      // 物流-获取时分秒
      if(v){
        return v.split(' ')[1]
      }else{
        return '';
      }
    },
    getTimet4(v){
      // 物流-获取时分秒
      if(v){
        return v.split('T')[1]
      }else{
        return '';
      }
    },
  },
  watch:{
    resultNum(n,o){
      this.pageNum=1
    },
    nowDate(n,o){
      this.arrdata = []
      this.arrdata = this.arrdata1
    },
    arr(n,o){
      // 数据刷新重置本地数据
      this.arrdata = n
      this.arrdata1 = n
    }
  },
  destroyed(){
    // 组件销毁清空定时变量
    clearInterval(this.t)
  }
}
</script>
<style lang="scss">
.joinCar {
    width: 340px;
   .el-message-box__header {
    margin-top:0px;padding:0px;height: 40px;line-height: 40px;background: #f3f3f3;margin-bottom:20px;
    .el-message-box__title {
      height: 40px;line-height: 40px;font-size: 18px;color:#8f929b;justify-content: flex-start;
      span {
        margin-left:10px;
      }
    }
   }
   .el-message-box__message {
    margin:10px 0px;
    svg {
      vertical-align: -10px;width: 30px;height: 30px;margin-right: 10px;fill: #8bc34a;
    }
   }
  .el-message-box__btns {
    button {
      &:hover {
        color:#333;background: rgba(0,0,0,0.08);border:1px solid #ccc;
      }
    }
    .el-button--primary {
      background: #f54d70;border:1px solid #f54d70;
      &:hover {
        color:#fff;background: #f54d70;opacity: 0.8;border:1px solid #f54d70;
      }
    }
  }
  .el-message-box__headerbtn {
    top:10px;
  }
}
</style>
<style lang="scss">
  .cntbox-logisticsinfo {
      padding:20px 0;padding-left:190px;min-height: 20px;max-height: 250px;overflow-y: auto;
      .info {
        h6 {
          position: relative;font-size: 12px;margin-bottom:5px;padding-top:4px;
          p {
            position: absolute;top:5px;left:-215px;font-weight: normal;font-size: 12px;text-align: right;width: 170px;
            span {
              font-size: 12px;
            }
            .sp {
              margin-left:15px;font-size: 12px;
            }
          }
          svg {
            fill: #797979;
            width: 24px;height: 24px;position: absolute;top:0px;left:-35px;
          }
        }
        .p {
          font-size: 12px;margin-bottom:10px;position: relative;
          .sp {
            position: absolute;top:0px;left:-93px;width: 60px;display: block;font-size: 12px;
          }
        }
      }
      /deep/.el-timeline {
        .el-timeline-item {
          .el-timeline-item__node {
            width: 20px;height: 20px;left:-5px;display: none;
          }
          &:first-child {
            // .el-timeline-item__node {
            //   background: red;
            // }
            .info {
              h6 {
                svg {
                  fill: red;
                }
              }
            }
          }
        }
      }
      // /deep/.el-timeline {
      //   .el-timeline-item {
      //     &:first-child {
      //       .el-timeline-item__node {
      //         width: 18px;height: 18px;left: -4px;
      //       }
      //       &:after {
      //         content: '';display: block;position: absolute;width: 4px;height:4px;border-radius: 50%;background: #e2231a;top:7px;left:3px;z-index: 999;box-shadow: 0px 0px 7px 5px rgba(226,35,26,0.4);
      //       }
      //     }
      //   }
      // }
      // .h6 {
      //   font-size: 14px;color:#333;border-bottom:1px solid #e3e3e3;height: 30px;line-height: 30px;margin-bottom:15px;
      // }
    }
</style>
<!-- <style lang="scss">
  .cntbox-logisticsinfo {
      /deep/.el-timeline {
        .el-timeline-item {
          &:first-child {
            .el-timeline-item__node {
              width: 18px;height: 18px;left: -4px;
            }
            &:after {
              content: '';display: block;position: absolute;width: 4px;height:4px;border-radius: 50%;background: #e2231a;top:7px;left:3px;z-index: 999;box-shadow: 0px 0px 7px 5px rgba(226,35,26,0.4);
            }
          }
        }
      }
      h6 {
        font-size: 14px;color:#333;border-bottom:1px solid #e3e3e3;height: 30px;line-height: 30px;margin-bottom:15px;
      }
    }
</style> -->
<style  lang="scss" scoped>
.myordertable {
  background: #fff;
  table {
    width: 100%;margin-top:20px;border-spacing:0px 0px;
    tr {
      th {
        background: #f9fafe;color:#787882;height: 50px;line-height: 50px;text-align: center;
      }
      td {
        padding:0px;text-align: center;margin:0px;
        &:nth-child(1) {
          width: 500px;
        }
        .tdbox {
          height: 125px;display: flex;align-items: center;justify-content: flex-start;width: 500px;padding:0px;margin:0px;border-bottom:1px solid #eee;padding-left:15px;
          .img {
            img {
              display: block;width: 65px;height: 65px;cursor: pointer;
            }
          }
          .info {
            margin-left:20px;width: 210px;
            h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;width: 210px;font-size: 14px;text-align: left;cursor: pointer;
            }
            p {
              text-align: left;color:#a3a3a3;font-size: 14px;

            }
          }
          .info1 {
            margin-left:20px;width: 170px;
            p {
              text-align: left;color:#a3a3a3;font-size: 14px;
              &:last-child {
                margin-top:10px;
              }
            }
          }
          &:last-child {
            border-bottom:0px;
          }
        }
        .n {
          color:#333;font-weight: bold;font-size: 14px;
        }
        .p {
          color:#ad0c14;font-weight: bold;font-size: 14px;
        }
        .tp {
          font-size:14px;font-weight: bold;color:#e67634;
        }
        .tp1 {
          font-size:14px;font-weight: bold;color:#8a8a8a;
        }
        .tp2 {
          font-size:14px;font-weight: bold;color:#7fa76b;
        }
        .tp3 {
          font-size:14px;font-weight: bold;color:#2f2f2f;
        }
        .pt {
          background: none;color:#576da9;font-size: 12px;margin-top:5px;
          &:hover {
            opacity: 0.8;
          }
        }
        .plgs {
          font-size:12px;margin-top:5px;
          svg {
            fill: #141414;vertical-align: -4px;margin-right: 5px;
          }
        }
        .sqsh {
          font-size:12px;color:#f99970;background: none;margin-top:5px;margin-right: 5px;
          svg {
            fill: #f99970;vertical-align: -4px;margin-right: 5px;
          }
        }
        .ltm {
          color:#e43656;font-size: 12px;margin-bottom:5px;
          svg {
            fill: #e43656;width: 20px;height: 20px;vertical-align: -5px;margin-right: 2px;
          }
        }
        .nfk {
          border:1px solid #e43656;color:#e43656;background: none;width: 74px;height: 22px;display: block;margin:0 auto;border-radius: 5px;font-size: 12px;font-weight: bold;margin-top:5px;
          &:hover {
            opacity: 0.8;
          }
        }
        .nfk1 {
          color:#86d044;border-color:#86d044;
        }
        .qxtm {
          background: none;font-size: 12px;color:#8b8b8b;display:block;margin:0 auto;margin-top:5px;
          &:hover {
            opacity: 0.8;
          }
        }
        .tdboxs {
          height: 125px;display: flex;align-items: center;padding:0px;margin:0px;border-bottom:1px solid #eee;
          &:last-child {
            border-bottom:0px;
          }
        }
        .handle {
          display: flex;justify-content: center;height: 100%;
          .lt {
            border-right:1px solid #ededed;display: flex;justify-content: center;align-items: center;width: calc(100% - 10px);padding:0 5px;
            // button {
            //   margin:0 5px;
            // }
          }
          .rt {
            width: calc(100% - 10px);padding:0 5px;
            .tddiv {
              height: 126px;display: flex;justify-content: center;align-items: center;width: 100%;border-bottom:1px solid #ededed;
              button {
                // margin:0 5px;
                background: none;color:#f99970;
                svg {
                  fill: #f99970;vertical-align: -3px;
                }
              }
              // .nfk {
              //    border: 1px solid #c01d20;display: block;color: #c01d20;    width: 75px;height: 25px;border-radius: 5px;
              // }
              &:last-child {
                border-bottom:0px;
              }
            }
          }
        }
      }
      &:nth-child(2n+2) {
        td {
          background: #f5f5f5;border-top: 12px solid #fff;color:#ababab;height: 36px;font-size: 14px;line-height: 36px;text-align: left;position: relative;
          .del {
            background: none;float:right;margin-right: 10px;
            svg {
              width: 18px;height: 18px;fill: #a5a5a5;margin-top:9px;
            }
          }
          .sp {
            color:#333333;
          }
          .sp1 {
            margin-left: 35px;position: absolute;top:0px;left:250px;
          }
          &:nth-child(1){
            padding-left:15px;color:#ccc;
          }
        }
      }
      &:nth-child(2n+3) {
        td {
          border:1px solid #eeeeee;border-top:0px;margin-bottom:50px;border-right:0px;
          &:last-child {
            border-right:1px solid #eee;
          }
        }
      }
    }
  }
  .page {
    text-align: center;padding:40px 0px;
    .back,.next {
      height: 28px;display: inline-block;vertical-align: -4px;background: none;border:1px solid #cccccc;font-size: 12px;width: 75px;text-align: center;border-radius: 5px;color:#ccc;
      &:hover {
        background-color: #ccc!important;
        color:#797979!important;
      }
    }
    /deep/.el-pagination {
      display: inline-block;
      .number {
        color:#ccc;
        &:hover {
          color:#797979!important;
        }
      }
      .el-icon-more {
        display: none;
      }
      .active {
        background-color: #ccc!important;
        color:#797979!important;
      }
    }
  }
}
</style>
