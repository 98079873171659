<template>
  <div class="usercenterhr">
  </div>
</template>
<script>
export default {
  name: 'usercenterhr',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.usercenterhr {
  width: 100%;height: 3px;background: #e75873;box-shadow: 0px 2px 5px 2px rgba(249,181,188,1);
}
</style>
