<template>
  <div class="usercenterlt">
    <div class="head">
      <div class="img">
        <img  v-if='headimg&&userData.WxDisplayUrl' :src="userData.WxDisplayUrl?userData.WxDisplayUrl:''">
        <svg v-if='headimg&&!userData.WxDisplayUrl'><use xlink:href="#iconzhanghao"></use></svg>
      </div>
      <h6>{{userData?userData.ContactName:''}}</h6>
    </div>
    <div class="item">
      <button @click='choiceBtm(0)' :class='{choice:choice==0}'>
        <svg v-if='choice!=0' class="icon"><use xlink:href="#icongerenxinximoren"></use></svg>
        <svg v-if='choice==0' class="icon"><use xlink:href="#icongerenxinxidianliang"></use></svg>个人信息</button>
      <button @click='choiceBtm(1)' :class='{choice:choice==1}'>我的订单</button>
      <button @click='choiceBtm(2)' :class='{choice:choice==2}'>评价管理</button>
      <button @click='choiceBtm(3)' :class='{choice:choice==3}'>预存余额</button>
      <button @click='choiceBtm(4)' :class='{choice:choice==4}'>优惠券</button>
      <button @click='choiceBtm(6)' :class='{choice:choice==6}'>收货地址</button>
      <button @click='choiceBtm(5)' :class='{choice:choice==5}'>个人资料</button>
    </div>
    <div class="helps">
<!--       <button><svg  class="icon"><use xlink:href="#iconbangzhu"></use></svg>帮助中心</button>
      <button><svg  class="icon"><use xlink:href="#iconwenti"></use></svg>联系我们</button> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'usercenterlt',
  data(){
    return {
      choice:0,
      userData:null,
      that:this,
      headimg:false,
    }
  },
  props: {
    nav: Number,
    imgupdata:{
      default:0,
      type:Number
    }
  },
  methods:{
    choiceBtm(v){
      this.choice = v
      if(v==0){
        // 个人中心跳转
        this._router({then:()=>{
          this.$router.push({path:'/usercenter.html'})
        },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:'/usercenter.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
      if(v==1){
        // 我的订单
        var _obj = {
          orderTypes:0,
          orderNumbers:'',
          pageIndexs:1
        }
        window.sessionStorage.setItem('ordersearchdataRe',JSON.stringify(_obj))
        this._router({then:()=>{
          this.$router.push('/usercenterorder.html')
        },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:'/usercenterorder.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
      if(v==2){
        // 评价管理
        var _obj = {
          orderTypes:1,
          pageIndexs:1
        }
        window.sessionStorage.setItem('ordersearchdataRe',JSON.stringify(_obj))
        this._router({then:()=>{
          this.$router.push('/userremark.html')
        },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:'/userremark.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
      if(v==3){
        // 评价管理
        // var _obj = {
        //   orderTypes:0,
        //   orderNumbers:'',
        //   pageIndexs:1
        // }
        // window.sessionStorage.setItem('ordersearchdataRe',JSON.stringify(_obj))
        this._router({then:()=>{
          this.$router.push('/userprice.html')
        },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:'/userprice.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
      if(v==4){
        // 优惠券
        var _obj = {
          orderTypes:1,
          pageIndexs:1
        }
        window.sessionStorage.setItem('ordersearchdataRe',JSON.stringify(_obj))
        this._router({then:()=>{
          this.$router.push('/userdiscount.html')
        },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:'/userdiscount.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
      if(v==5){
        // 评价管理
        // var _obj = {
        //   orderTypes:0,
        //   orderNumbers:'',
        //   pageIndexs:1
        // }
        // window.sessionStorage.setItem('ordersearchdataRe',JSON.stringify(_obj))
        this._router({then:()=>{
          this.$router.push('/userdata.html')
        },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:'/userdata.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
      if(v==6){
        // 评价管理
        // var _obj = {
        //   orderTypes:0,
        //   orderNumbers:'',
        //   pageIndexs:1
        // }
        // window.sessionStorage.setItem('ordersearchdataRe',JSON.stringify(_obj))
        this._router({then:()=>{
          this.$router.push('/addressdata.html')
        },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:'/addressdata.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
    },
    inituserinfo(){
      // 用户信息刷新
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainRelatedUserInfo',this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              this.headimg = true
              this.userData = res.data.Data.customer
              this.$emit('exportUserData',res.data.Data)
            }
          }, res => {
          })
      },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.choice = this.nav
      this.inituserinfo()
    })
  },
  filters:{
    imgsrcs(v,that){
      return that._src + v
    }
  },
  watch:{
    imgupdata(n,o){
      this.inituserinfo()
    }
  }
}
</script>
<style  lang="scss" scoped>
.usercenterlt {
  background:#fff;position: relative;
  .head {
    .img {
      width: 60px;height: 60px;margin:0 auto;display: block;padding-top:30px;
      img {
        width: 60px;height: 60px;margin:0 auto;display: block;border-radius: 50%;
      }
      svg {
        width: 60px;height: 60px;fill: #ed008c;
      }
    }
    h6 {
      text-align: center;font-weight: normal;color:#737373;font-size: 14px;height: 46px;line-height: 46px;
    }
  }
  .item {
    min-height: 743px;
    button {
      display: block;background: none;height: 30px;line-height: 30px;text-align: left;width: 100%;position: relative;padding-left:56px;
      svg {
        position: absolute;top:4px;left:25px;width: 20px;height: 20px;
      }
      &:after {
        content: '';display: block;position: absolute;width: 2px;height: 26px;background: #f44e5d;top:2px;left:2px;display: none;
      }
      &:nth-child(1) {
        font-size: 16px;font-weight:bold;
        &:after {
          display: none;
        }
      }
    }
    .choice {
      color:#f44e5d;
      &:after {
        display: block;
      }
    }
  }
  .helps {
    position: absolute;bottom:45px;left:0px;width: 100%;
    button {
      font-size: 16px;color:#333;display: block;height: 40px;line-height: 40px;background: none;width: 100%;font-weight: bold;
      svg {
        width: 22px;height: 22px;vertical-align: -5px;margin-right: 10px;
      }
    }
  }
}
</style>
