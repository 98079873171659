<!-- 个人中心--我的订单 -->
<template>
  <div class="usercenterorder">
    <usercenterhr class='usercenterhr'></usercenterhr>
    <usercenterlt class='usercenterlt' :nav='1'></usercenterlt>
    <myorderclass class='myorderclass' :waits='waits' :waits1='waits1' :waits2='waits2' @exportTypes='exportTypes' @exportsearch='exportsearch' :resultNum='resultNum1' :resultNum1='resultNum2' ></myorderclass>
    <div v-show='loadings' class="loading">
      <svg><use xlink:href="#iconloading"></use></svg>
    </div>
    <div v-show='emptys' class="empty">暂无订单</div>
  <myordertable class='myordertable' v-if='orderData&&orderData.length>0' @exportTypes='exportTypes'  @exportPages='exportPages' :TotalCount='TotalCount' :arr='orderData' :resultNum='resultNum' @exportresult='exportresult'></myordertable>
  </div>
</template>
<script>
import usercenterhr from '@/components/usercenter-hr/usercenter-hr.vue';//顶部hr1
import usercenterlt from '@/components/usercenter-lt/usercenter-lt.vue';//左侧栏目1
import myorderclass from '@/components/myorder-class/myorder-class.vue';//我的订单头部分类和搜索1
import myordertable from '@/components/myorder-table/myorder-table.vue';//我的订单头部1
export default {
  name: 'usercenterorder',
  components: {
    usercenterhr,//1
    usercenterlt,//1
    myorderclass,//1
    myordertable//1
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'我的订单-微微定',
      orderData:[],//订单列表，
      TotalCount:null,//列表总条数
      orderTypes:0,//订单分类状态
      orderNumbers:'',//订单号查询
      pageIndexs:1,//分页跳转
      resultNum:0,//重置计数--页码
      resultNum1:0,//重置计数--查询订单号
      resultNum2:0,//重置计数--订单分类
      waits:null,
      waits1:null,
      waits2:null,
      emptys:false,//空数据
      loadings:false,//加载动画
    }
  },
  props: {
    msg: String
  },
  methods:{
    exportresult(){
      // 取消订单刷新
      this.resultGet()
    },
    exportsearch(v){
      // 查询订单号跳转
      this.resultNum+=1
      this.resultNum2+=1
      this.pageIndexs=1
      this.orderTypes=0

      this.orderNumbers = v
      this.getData({types:this.orderTypes,number:this.orderNumbers,indexs:this.pageIndexs,sizes:10,fn:(res)=>{
        // console.log(res)
        if(res.data.Data){
          this.orderData = res.data.Data.list.List
          this.TotalCount = res.data.Data.list.TotalCount
          this.waits = res.data.Data.waitingpayNum
          this.waits1 = res.data.Data.waitingreceiptNum
          this.waits2 = res.data.Data.waitevaluationNum
          var _obj = {
            orderTypes:this.orderTypes,
            orderNumbers:this.orderNumbers,
            pageIndexs:this.pageIndexs
          }
          window.sessionStorage.setItem('ordersearchdata',JSON.stringify(_obj))
        }
      }})
    },
    exportTypes(v){
      // 分类跳转
      this.resultNum+=1
      this.resultNum1+=1
      this.orderNumbers=''
      this.pageIndexs=1
      this.loadings = true;
      this.orderTypes = v

      var _obj = {
        orderTypes:v,
        orderNumbers:'',
        pageIndexs:1
      }
      window.sessionStorage.setItem('ordersearchdataRe',JSON.stringify(_obj))
      this.getData({types:this.orderTypes,number:this.orderNumbers,indexs:this.pageIndexs,sizes:10,fn:(res)=>{
        // console.log(res)
        if(res.data.Data){
          this.orderData = res.data.Data.list.List
          this.TotalCount = res.data.Data.list.TotalCount
          this.waits = res.data.Data.waitingpayNum
          this.waits1 = res.data.Data.waitingreceiptNum
          this.waits2 = res.data.Data.waitevaluationNum
          var _obj = {
            orderTypes:this.orderTypes,
            orderNumbers:this.orderNumbers,
            pageIndexs:this.pageIndexs
          }
          window.sessionStorage.setItem('ordersearchdata',JSON.stringify(_obj))
        }
      }})
    },
    getData(param){
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      // 请求数据
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/MyOrderList?OrderStaus='+param.types+'&SearchName='+param.number+'&pageIndex='+param.indexs+'&pageSize='+param.sizes,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              this.loadings = false;
              if(res.data.Data.list.List.length<1){
                this.emptys = true
              }else{
                this.emptys = false
              }
              this.$store.dispatch("cmtorderTypeClick",this.$store.state.orderTypeClick+=1)
              if(param.fn){
                param.fn(res)
              }
            }
          }, res => {
            console.log('系统异常')
          })
      },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:'/usercenterorder.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
    },
    exportPages(v){
      // 分页跳转
      this.pageIndexs = v;
      this.getData({types:this.orderTypes,number:this.orderNumbers,indexs:v,sizes:10,fn:(res)=>{
        // console.log(res)
        if(res.data.Data){
          this.orderData = res.data.Data.list.List
          this.TotalCount = res.data.Data.list.TotalCount
          this.waits = res.data.Data.waitingpayNum
          this.waits1 = res.data.Data.waitingreceiptNum
          this.waits2 = res.data.Data.waitevaluationNum
          var _obj = {
            orderTypes:this.orderTypes,
            orderNumbers:this.orderNumbers,
            pageIndexs:this.pageIndexs
          }
          window.sessionStorage.setItem('ordersearchdata',JSON.stringify(_obj))
        }
      }})
    },
    resultGet(){
      var _ordersearchdata = JSON.parse(window.sessionStorage.getItem('ordersearchdata'))
      var _ordersearchdataRe = JSON.parse(window.sessionStorage.getItem('ordersearchdataRe'))
      if(_ordersearchdata){
        this.orderTypes = _ordersearchdata.orderTypes
        this.orderNumbers = _ordersearchdata.orderNumbers
        this.pageIndexs = _ordersearchdata.pageIndexs
      }else if(_ordersearchdataRe){
        this.orderTypes = _ordersearchdataRe.orderTypes
        this.orderNumbers = _ordersearchdataRe.orderNumbers
        this.pageIndexs = _ordersearchdataRe.pageIndexs
      }
      // console.log(_ordersearchdata,this.orderTypes)
      this.getData({types:this.orderTypes,number:this.orderNumbers,indexs:this.pageIndexs,sizes:10,fn:(res)=>{
        // console.log(res)
        if(res.data.Data){
          this.orderData = res.data.Data.list.List
          this.TotalCount = res.data.Data.list.TotalCount
          this.waits = res.data.Data.waitingpayNum
          this.waits1 = res.data.Data.waitingreceiptNum
          this.waits2 = res.data.Data.waitevaluationNum
        }
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.loadings = true;
        this.resultGet()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.usercenterorder {
  width: 1200px;margin:0 auto;zoom:1;padding-bottom:20px;
  .usercenterhr {
    width: 1200px;margin:0 auto;margin-top:20px;margin-bottom:10px;position: relative;z-index: 9;
  }
  .usercenterlt {
    float:left;width: 180px;margin-top:-10px;
  }
  .myorderclass {
    float:right;width: 1010px;margin-top:-10px;margin-bottom:1px;
  }
  .myordertable {
    float:right;width: 970px;padding:0 20px;
  }
  .empty {
    width: 1010px;text-align: center;height: 60px;line-height: 60px;background: #fff;float:right;color:#797979;
  }
  .loading {
     width: 1010px;float:right;height: 0px;text-align: center;position: relative;z-index: 9;
    svg {
      width: 30px;height: 30px;animation:turn 1s linear infinite;margin-top:100px;
    }
    @keyframes turn{
      0%{-webkit-transform:rotate(0deg);}
      25%{-webkit-transform:rotate(90deg);}
      50%{-webkit-transform:rotate(180deg);}
      75%{-webkit-transform:rotate(270deg);}
      100%{-webkit-transform:rotate(360deg);}
    }
  }
  &:after {
    content: "";display: block;clear:both;
  }
}
</style>
